<template>
  <div class="home">
    <div class="home_body">
      <div class="one">
        <span>你的算力(T):</span>
        <input type="number" v-model="input">
        <button @click="calculate">计算</button>
      </div>
      <div class="one">
        <span>目前全网算力:   {{obj.networkHashrate}}E</span>
      </div>
      <div class="one">
        <span>hp矿池算力:   {{obj.poolHashrate}}E</span>
      </div>
      <div class="one">
        <span>hp每p收益(XCH):   {{obj.miningEarningsP}}</span>
      </div>
      <div class="one">
        <span>XCH单价(USDT):   {{obj.priceUsd}}</span>
      </div>
      <div class="one">
        <span>XCH(CNY):   {{obj.priceCny}}</span>
      </div>
      <div class="one">
        <span>每T收益(USDT):   {{obj.earningsUsdT}}</span>
      </div>
      <div class="one">
        <span>每T收益(CNY):   {{obj.earningsCnyT}}</span>
      </div>
      <div class="one">
        <span>预估收益(USDT):   <span class="color">{{USDT?USDT:'0.000'}}</span></span>
      </div>
      <div class="one">
        <span>预估收益(CNY):   <span class="color">{{CNY?CNY:'0.000'}}</span></span>
      </div>
      <div class="one">
        <span>预估收益(XCH):   <span class="color">{{XCH?XCH:'0.000'}}</span></span>
      </div>
      <!-- <div class="polo">
        <span>下载地址:  <span>https://invite.shifen.cn/</span></span>
      </div> -->
    </div>
    <div class="lisa">
      <button @click="download">下载</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data(){
    return{
      input:'',
      obj:Object,
      USDT:'',
      CNY:'',
      XCH:''
    }
  },
  created(){
    this.getList()
  },
  methods:{
    getList(){
      this.$axios.get('https://api.polo.acorn.cn/api/polo/coin/chia/hpool').then((res)=>{
        if (res.data.code === '000000'){
          this.obj = res.data.data
        }
      })
    },
    calculate(){
      // if(this.input){
        const m = this.obj
        this.USDT = this.input ? ((m.earningsUsdT)*Number(this.input)).toFixed(3) : '0.000'
        this.CNY = this.input ? ((m.earningsCnyT)*Number(this.input)).toFixed(3) : '0.000'
        this.XCH = this.input ? ((m.miningEarningsT)*Number(this.input)).toFixed(3) : '0.000'
      // }
    },
    download(){
      window.location.href = 'https://invite.shifen.cn/'
    }
  }
}
</script>

<style scoped>
html,body{padding: 0;margin: 0;}
.home{
  width: 100%;
  margin:20px auto;
}
.home .home_body{
  width: 80%;
  margin: 0 auto;
}
.home .one:first-child{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home .one:first-child>span{
  width: 25%;
}
.home .one:first-child>input{
  /* display: block; */
  width: 40%;
  height: 30px;
  margin-left: 2px;
  border-radius: 2px;
  letter-spacing: normal;
  /* background: none; */
  outline: none;
  border: 1px solid #000000;
}
.home .one:first-child>button{
  width: 20%;
  height: 30px;
  /* line-height: 25px; */
  background-color: #fb9028;
  color: #ffffff;
  border-radius: 5px;
  border: none;
}
.home .one{
  padding: 10px 0;
}
.home .one span{
  font-size: 14px;
}
.home .one button{
  margin-left: 10px;
}
.color{
  color: #ff0000;
}
.home .polo{
  margin-top: 100px;
}
.home .polo span{
  font-size: 14px;
}
.home .polo span>span{
  font-size: 14px;
  color: #51c332;
}
.lisa{
  /* display: flex; */
  width: 100%;
  height: 50px;
  border-radius: 8px;
  position: fixed;
  left: 0;
  bottom:0;
  padding-bottom: 20px;
}
.lisa button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 50px;
  border-radius: 8px;
  background-color:#51c332;
  margin: 0 auto;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  font-size: 20px;
  color: white;
  letter-spacing: 5px;
}
@media screen and (max-width: 767px){
  .home{
  width: 100%;
  margin:20px auto;
  /* padding-left: 10px; */
}
.home .home_body{
  width: 80%;
  margin: 0 auto;
}
.home .home_body .one:first-child{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home .home_body .one:first-child>span{
  width: 25%;
  white-space:nowrap;
}
.home .home_body .one:first-child>input{
  display: block;
  /* width: 40%; */
  height: 20px;
  line-height: 20px;
  margin-left: 2px;
  border-radius: 2px;
  letter-spacing: normal;
  /* background: none; */
  outline: none;
  border: 1px solid #000000;
}
.home .home_body .one:first-child>button{
  width: 20%;
  height: 30px;
  /* line-height: 25px; */
  background-color: #fb9028;
  color: #ffffff;
  border-radius: 6px;
  border: none;
}
.home .one{
  padding: 6px 0;
}
.home .one span{
  font-size: 14px;
}
.color{
  color: #ff0000;
}
.home .polo{
  margin-top: 100px;
}
.home .polo span{
  font-size: 14px;
}
.home .polo span>span{
  font-size: 14px;
  color: #51c332;
}
.lisa{
  /* display: flex; */
  width: 100%;
  height: 50px;
  border-radius: 8px;
  position: fixed;
  left: 0;
  bottom:0;
  padding-bottom: 10px;
  z-index: 99;
}
.lisa button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 50px;
  border-radius: 8px;
  background-color:#51c332;
  margin: 0 auto;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  font-size: 20px;
  color: white;
  letter-spacing: 5px;
}
}
</style>
